import {
    Modal,
    ModalBackDrop,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from '@common/components/Modal';
import { PrimaryButton } from '@common/controls/Button';
import i18n from '@common/i18n';
import React from 'react';
import * as Styles from './styles';

const linkify = (labelKey: string, hyperlinks: Record<string, string | undefined>): string => {
    return i18n(labelKey)
        .split(/{|}/g)
        .filter((part: string) => {
            return !!part;
        })
        .map((part: string) => {
            const key = Object.keys(hyperlinks).find((k) => k === part);
            if (!key) return part;
            return (
                <a key={key} href={hyperlinks[key]} target="_blank" rel="noopener noreferrer">
                    {i18n(key)}
                </a>
            );
        }).join('');
};

type PropsType = {
    performanceAccepted: boolean;
    advertisingAccepted: boolean;
    hasUnsavedChanges: boolean;
    cookiePageUrl: string | undefined;
    togglePerformance: () => void;
    toggleAdvertising: () => void;
    rejectAllCookies: () => void;
    acceptAllCookies: () => void;
    saveChanges: () => void;
};

const ModalView = (props: PropsType): JSX.Element => (
    <Styles.ModalViewStyleContainer>
        <Modal>
            <ModalHeader closeHandler={null}>
                <span className="cookie-notice-title">{i18n('get_a_better_experience')}</span>
            </ModalHeader>
            <ModalBody className="cookie-consent">
                <div className="form-group">
                    <h5>{i18n('essential_and_functional')}</h5>
                    {i18n('essential_and_functional_info')}
                </div>
                <div className="form-group">
                    <h5>{i18n('additional_cookies')}</h5>
                    <label htmlFor="performance-cookie">
                        <input
                            type="checkbox"
                            id="performance-cookie"
                            checked={props.performanceAccepted}
                            onChange={props.togglePerformance}
                        />
                        <strong>{`${i18n('performance')}: `}</strong>
                        {i18n('performance_info')}
                    </label>
                    <label htmlFor="advertising-cookie">
                        <input
                            type="checkbox"
                            id="advertising-cookie"
                            checked={props.advertisingAccepted}
                            onChange={props.toggleAdvertising}
                        />
                        <strong>{`${i18n('advertising')}: `}</strong>
                        {i18n('advertising_info')}
                    </label>
                </div>
                <div className="form-group">
                    <span className="cookie-notice-link">
                        {linkify('to_learn_more', { cookie_notice: props.cookiePageUrl })}
                    </span>
                </div>
            </ModalBody>
            <ModalFooter>
                {props.hasUnsavedChanges ? (
                    <PrimaryButton className="btn btn-primary" onClick={props.saveChanges}>
                        {i18n('accept_changes')}
                    </PrimaryButton>
                ) : (
                    <PrimaryButton className="btn btn-primary" onClick={props.acceptAllCookies}>
                        {i18n('accept_all_cookies')}
                    </PrimaryButton>
                )}
                <PrimaryButton className="btn btn-primary" onClick={props.rejectAllCookies}>
                    {i18n('reject_all_cookies')}
                </PrimaryButton>
            </ModalFooter>
        </Modal>
        <ModalBackDrop />
    </Styles.ModalViewStyleContainer>
);

export default ModalView;
